import React from 'react';
import Responsive from 'react-responsive';

const DEPLOY_ENV = 'production'


const CONFIG = {
  production: {
    wxWebAppId: 'wx6a0a847481e2009e',
    domain: 'https://m.shenmanz.com',
    backend: {
      base: 'https://api.shenmanz.com',
      base2: 'https://api_new.shenmanz.com',
      image: 'https://image.shenmanz.com',
      s3: 'https://baixing-img.s3-ap-southeast-2.amazonaws.com'
    },
    algolia: { topic: 'prod_topic', question: 'prod_questions' },
    stripeKey: 'pk_live_Y6KLUEm8iWtc3uYxDo8Crome',
    REACT_APP_PUSHER_KEY: 'fd295af7744654979bcc'
  },
  staging: {
    wxWebAppId: 'wx6a0a847481e2009e',
    domain: 'https://staging.shenmanz.com',
    backend: {
      base: 'https://api.staging.shenmanz.com',
      image: 'https://image.staging.shenmanz.com',
      s3: 'https://baixing-img-staging.s3-ap-southeast-2.amazonaws.com'
    },
    algolia: { topic: 'staging_topic', question: 'staging_questions' },
    stripeKey: 'pk_test_Q8Spef9R9kI3kba8aSEuYU52',
    REACT_APP_PUSHER_KEY: '2c18a1cf619d43b57a45'
  },
  local: {
    backend: {
      base: 'http://api.shenma.test',
      image: 'https://image.staging.shenmanz.com',
      s3: 'https://baixing-img-staging.s3-ap-southeast-2.amazonaws.com'
    },
    algolia: { topic: 'staging_topic', question: 'staging_questions' },
    stripeKey: 'pk_test_Q8Spef9R9kI3kba8aSEuYU52',
    REACT_APP_PUSHER_KEY: '2c18a1cf619d43b57a45'
  },
  dev: {
    backend: {
      base: 'http://api.shenma.test',
      image: 'https://image.staging.shenmanz.com',
      s3: 'https://baixing-img-staging.s3-ap-southeast-2.amazonaws.com'
    },
    algolia: { topic: 'local_topic', question: 'local_questions' },
    stripeKey: 'pk_test_Q8Spef9R9kI3kba8aSEuYU52'
  }
};

export default CONFIG[DEPLOY_ENV];

export const Desktop = props => <Responsive {...props} minWidth={768} />;
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
